import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Box, Container } from "@chakra-ui/react";
import Home from "./Layout/Home";
import Onboarding from "./components/Dashboard/Onboarding";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  return (
    <Box>
      <Home />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/onboarding" element={<Onboarding />} />
      </Routes>
    </Box>
  );
}

export default App;
