import { Box, Button, Center, FormLabel, Input, Text } from "@chakra-ui/react";
import React from "react";
import { useAddOnboarding } from "../../data/onboarding/onboardingQuery";
import { Spinner } from "@chakra-ui/react";
import { Formik } from "formik";

const Onboarding = () => {
  const initialValues = {
    merchantcode: "",
    mobile: "",
    email: "",
    firm: "",
    is_new: "0",
    callback:
      "https://payism-verify.metrictreelabs.com/onboarding-web/callback-url",
  };

  const {
    mutate: addDetails,
    isLoading,
    isSuccess,
    isError,
    status,
    data,
  } = useAddOnboarding();

  return (
    <Box h={"100vh"} marginLeft={"250px"}>
      <Center pt={30}>
        <Text fontSize="3xl" pt={10} fontWeight="semibold">
          Onboarding
        </Text>
      </Center>
      <Center p={30}>
        <Box w="500px" overflow="hidden" mt={10}>
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.merchantcode) {
                errors.merchantcode = "Required";
              }
              if (!values.mobile) {
                errors.mobile = "Required";
              } else if (values.mobile.length !== 10) {
                errors.mobile = "Phone number should be 10 digit number.";
              }
              if (!values.firm) {
                errors.firm = "Required";
              }

              return errors;
            }}
            onSubmit={(values) => {
              addDetails(values);
            }}
          >
            {({ errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box pt={10}>
                  <FormLabel>Merchant Code</FormLabel>
                  <Input
                    name="merchantcode"
                    placeholder="Enter Merchant Code"
                    onChange={handleChange}
                    size="lg"
                    borderColor="Black"
                  />
                  <Text color="red" mt="2">
                    {errors.merchantcode &&
                      touched.merchantcode &&
                      errors.merchantcode}
                  </Text>
                </Box>
                <Box pt={5}>
                  <FormLabel>Mobile</FormLabel>
                  <Input
                    placeholder="Enter Mobile No"
                    name="mobile"
                    onChange={handleChange}
                    size="lg"
                    borderColor="Black"
                  />
                  <Text color="red" mt="2">
                    {errors.mobile && touched.mobile && errors.mobile}
                  </Text>
                </Box>
                <Box pt={5}>
                  <FormLabel>Email Id</FormLabel>
                  <Input
                    placeholder="Enter Email Id"
                    name="email"
                    onChange={handleChange}
                    size="lg"
                    borderColor="Black"
                  />
                  <Text color="red" mt="2">
                    {errors.email && touched.email && errors.email}
                  </Text>
                </Box>
                <Box pt={5}>
                  <FormLabel>Firm</FormLabel>
                  <Input
                    placeholder="Enter firm"
                    name="firm"
                    onChange={handleChange}
                    size="lg"
                    borderColor="Black"
                  />
                  <Text color="red" mt="2">
                    {errors.firm && touched.firm && errors.firm}
                  </Text>
                </Box>
                <Box pt={10}>
                  <Button type="submit" w="full" colorScheme="purple" size="lg">
                    {isLoading === true ? <Spinner /> : <Text> Submit</Text>}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Center>
    </Box>
  );
};

export default Onboarding;
