import { useMutation } from "react-query";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

const addDetails = (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/onboarding-web/onboarding`,
    data
  );
};

export const useAddOnboarding = () => {
  const toast = useToast();

  return useMutation(addDetails, {
    onSuccess: (res) => {
      window.open(res.data.data.redirecturl);
    },
    onError: (error) => {
      const errMsg = error.response.data.message;
      toast({
        title: "Error",
        position: "bottom-right",
        description: errMsg,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    },
  });
};
