import React from "react";
import { chakra, Box, Button, Center } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  return (
    <Box marginLeft="250px">
      <Box pos="relative" overflow="hidden">
        <Box maxW="7xl" mx="auto">
          <Box
            pos="relative"
            pb={{
              base: 8,
              sm: 16,
              md: 20,
              lg: 28,
              xl: 32,
            }}
            w="full"
            border="solid 1px transparent"
          >
            <Box
              maxW={{
                base: "7xl",
              }}
              px={{
                base: 4,
                sm: 6,
                lg: 8,
              }}
              mt={{
                base: 12,
                md: 16,
                lg: 20,
                xl: 28,
              }}
            >
              <Box
                textAlign="center"
                w={{
                  base: "full",
                  md: 11 / 12,
                  xl: 8 / 12,
                }}
                mx="auto"
              >
                <chakra.h1
                  fontSize={{
                    base: "4xl",
                    sm: "5xl",
                    md: "6xl",
                  }}
                  letterSpacing="tight"
                  lineHeight="short"
                  fontWeight="extrabold"
                  color="gray.900"
                >
                  <chakra.span
                    display={{
                      base: "block",
                      xl: "inline",
                    }}
                  >
                    Payism{" "}
                  </chakra.span>
                  <chakra.span
                    display={{
                      base: "block",
                      xl: "inline",
                    }}
                    color="#666db3"
                  >
                    Get started
                  </chakra.span>
                </chakra.h1>
                <Center mt="30px">
                  <NavLink to={"/onboarding"}>
                    <Button colorScheme="purple" size="lg">
                      Onboarding
                    </Button>
                  </NavLink>
                </Center>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
